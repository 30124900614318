import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <div className="container">
      <div className="row mt-5 mb-3">
        <div className="col-12 text-center">
          <h1>Page not found</h1>
          <p>We couldn't find the page you where looking for.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
